/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");

import * as PhotoSwipe from "photoswipe";
import * as PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default";

window.Vue = require("vue");

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const header = new Vue({
    el: "#header",

    methods: {
        mobiletoggle: function (event) {
            // `this` inside methods points to the Vue instance
            event.target.classList.toggle("active");
        }
    }
});

const scrollOpts = {
    behavior: "smooth",
    block: "center",
    inline: "nearest"
};

const sidebarEl = document.getElementById('sidebar');
if (sidebarEl) {
    const sidebar = new Vue({
        el: sidebarEl,
        mounted: function () {
            this.scrollToActive(); //method1 will execute at pageload
        },

        methods: {
            menutoggle: function (event) {
                // `this` inside methods points to the Vue instance
                event.target.classList.toggle("active");
                this.scrollToActive(event);
            },
            scrollToActive: function (event) {
                setTimeout(function () {
                    var element = sidebarEl.querySelector(".active");
                    element.scrollIntoView(scrollOpts);
                }, 500);
            }
        }
    });
}
// const modal = new Vue({
//     el: "#embedElement",
//     methods: {
//         toggleModal: function(event) {
//             document.getElementById("embed").classList.toggle("active");
//         }
//     }
// });

function getExpandImageClick(imgEl) {
    return e => {
        e.preventDefault();
        initializeSwiper([
            {
                src: imgEl.src,
                w: imgEl.width * 3,
                h: imgEl.height * 3
            }
        ]);
    }
}

document.querySelectorAll("main img:not(.no-expand)").forEach(imgEl => {
    var wrap = document.createElement('div');
    wrap.classList.add('js-expand-image-btn');
    imgEl.parentElement.insertBefore(wrap, imgEl);
    imgEl.parentElement.addEventListener("click", getExpandImageClick(imgEl));
    wrap.appendChild(imgEl);
});

// replace any hyphens in brand names for dashboard (needed for beyond-100-years brand)
document.querySelectorAll(".brand--dashboard .grid__title").forEach(brandTitle => {
    const regex = /-/gi;
    brandTitle.innerHTML = brandTitle.innerHTML.replace(regex, ' ');
});

document.querySelector('#sidebar .active').scrollIntoView(scrollOpts);

function initializeSwiper(items) {
    var pswpElement = document.querySelectorAll(".pswp")[0];

    // define options (if needed)
    var options = {
        // optionName: 'option value'
        // for example:
        index: 0, // start at first slide
        shareEl: false,
        zoomEl: true,
        fullscreenEl: false,
    };

    // Initializes and opens PhotoSwipe
    var gallery = new PhotoSwipe(
        pswpElement,
        PhotoSwipeUI_Default,
        items,
        options
    );
    gallery.init();
}

//next button
$('.btn--next').click(function () {
    var active = $('#sidebar nav .active').first();
    var next;
    if (active.hasClass('parent')) {
        next = active.next('ul').find('li').first().find('a');
    } else {
        next =
            active.parent().next('li').length ? active.parent().next('li').children().first()
                : active.parent().parents('li').next('li').find('a');
    }

    if (next.length) {
        location.href = next.attr('href');
    }
});

// COLLAPSIBLE TABLE 

// document.querySelectorAll(".collapsible thead").forEach(header => {
//     header.addEventListener("click", () => {
//         const tbody = header.nextElementSibling;
//         const chevron = header.querySelector(".chevron");

//         tbody.style.display = tbody.style.display === "none" ? "contents" : "none";
//         chevron.classList.toggle("rotate");
//     });
// });